import dayjs from 'dayjs';
import { BasketSchedule, CreateBasketInput, Product, ProductsByDate } from 'types';
import { ActivityTicket, Supplier } from 'interfaces';
import { formatPenceToPounds } from 'utils/formatPrice';
import { UtmParamsData } from './ActivityDetails';
import qs from 'query-string';
import { BlockTrialTypeEnum } from 'enums';

export const priceText = (opts: { bookingPrice?: number; subscription?: boolean }) => {
  if (opts.bookingPrice) {
    const bookingPriceInPounds = opts.bookingPrice / 100;

    return `£${bookingPriceInPounds.toFixed(2)} / ${opts.subscription ? 'month' : 'session'}`;
  }
};

export const supplierDetailsExist = (supplier: Supplier): boolean => {
  if (!supplier) {
    return false;
  }

  return Boolean(
    supplier?.aboutUs &&
      supplier?.ageRange &&
      supplier?.cloudinaryImageId &&
      supplier?.activitiesLocation,
  );
};

export const roundUpToClosestIncrementOf5 = (totalActivityBookings: number) => {
  // Calculate the remainder when dividing the number by 5
  const remainder = totalActivityBookings % 5;
  // If remainder is greater than 0, round up to the next minimal increment of 5
  if (remainder > 0) {
    return totalActivityBookings - remainder;
  }
  return totalActivityBookings;
};

export const getHoursSinceLastBooking = (lastBookingTime: string) => {
  const currentTime = dayjs();
  const lastBookingTimestamp = dayjs(lastBookingTime);
  const hoursSinceLastBooking = currentTime.diff(lastBookingTimestamp, 'hour');
  return hoursSinceLastBooking;
};

export const getMinutesSinceLastBooking = (lastBookingTime: string) => {
  const currentTime = dayjs();
  const lastBookingTimestamp = dayjs(lastBookingTime);
  const minutesSinceLastBooking = currentTime.diff(lastBookingTimestamp, 'minutes');
  return minutesSinceLastBooking > 0 ? minutesSinceLastBooking : 1;
};

export const getSocialProofBannerTitle = (
  lastBookingTime: string | null,
  totalActivityBookings: number,
): string | null => {
  if (!lastBookingTime) return null;
  const hoursSinceLastBooking = getHoursSinceLastBooking(lastBookingTime);
  const minutesSinceLastBooking = getMinutesSinceLastBooking(lastBookingTime);
  const minutesBookedTitle = `Last booked just ${minutesSinceLastBooking} minute${
    minutesSinceLastBooking > 1 ? 's' : ''
  } ago - don't miss out!`;
  const hourBookedTitle = `Last booked just ${hoursSinceLastBooking} hour${
    hoursSinceLastBooking > 1 ? 's' : ''
  } ago - don't miss out!`;
  const bookingCountTitle = `In demand! Join ${roundUpToClosestIncrementOf5(
    totalActivityBookings,
  )}+ others who've already booked!`;
  if (hoursSinceLastBooking < 1) {
    return minutesBookedTitle;
  } else if (hoursSinceLastBooking <= 6) {
    return hourBookedTitle;
  } else if (totalActivityBookings >= 5) {
    return bookingCountTitle;
  } else if (hoursSinceLastBooking <= 48) {
    return hourBookedTitle;
  } else {
    return null;
  }
};

export const getTicketPriceSummary = (allTickets: ActivityTicket[]): string => {
  // get all unique ticket prices.
  const allAvailableTicketPrices = Array.from(
    new Set(allTickets.filter((ticket) => ticket.isAvailable).map((ticket) => ticket.price)),
  );

  // if all tickets are same price, return.
  if (allAvailableTicketPrices.length === 1) {
    return allAvailableTicketPrices[0] === 0
      ? 'from Free'
      : `from ${formatPenceToPounds(allAvailableTicketPrices[0])}`;
  }

  const sortedByPrice = allAvailableTicketPrices.sort((a, b) => {
    return a - b;
  });

  const highestPrice = sortedByPrice[sortedByPrice.length - 1];
  const lowestPrice = sortedByPrice[0];

  if (lowestPrice === 0) return `from Free - ${formatPenceToPounds(highestPrice)}`;

  return `from ${formatPenceToPounds(lowestPrice)} - ${formatPenceToPounds(highestPrice)}`;
};

export const formatAllTicketsInBasket = (basketSchedules: BasketSchedule[]) => {
  return basketSchedules.map((schedule) => {
    const { ticket, selectedSessions } = schedule;

    const isBlockSession = selectedSessions?.[0]?.checkoutKey === 'block';

    if (isBlockSession) {
      // Block sessions, return the block id + checksum
      return {
        ticket: ticket.ticketId,
        block: selectedSessions[0]?.checkoutValue,
        checksum: selectedSessions[0]?.checksum,
      };
    } else {
      // Individual (single + multi) sessions, return an array of session ids
      const sessionIds = selectedSessions?.map((session) => session.checkoutValue);
      return {
        ticket: ticket.ticketId,
        sessions: sessionIds,
      };
    }
  });
};

export const getSubscriptionBasketInput = (
  selectedSession: Product | null | undefined,
  activityId: string,
  utmParams: UtmParamsData,
  ticketId?: string,
) => {
  if (!selectedSession) return;

  const subsTicket = [
    {
      ticket: ticketId,
      [selectedSession.checkoutKey]: selectedSession.checkoutValue,
    },
  ];

  const createBasketInput: CreateBasketInput = {
    activity: activityId,
    tickets: subsTicket,
    utmMeta:
      Object.keys(utmParams).length === 0
        ? {}
        : {
            utm_source: utmParams?.utm_source ?? null,
            utm_medium: utmParams?.utm_medium ?? null,
            utm_campaign: utmParams?.utm_campaign ?? null,
          },
  };

  return createBasketInput;
};

export const getBlockTrialBasketInput = (
  selectedSession: Product | null | undefined,
  activityId: string,
  blockTrialType: BlockTrialTypeEnum,
  blockTrialSessionCount: number,
  blockTrialPrice: number,
  utmParams: UtmParamsData,
  ticketId?: string,
) => {
  if (!selectedSession || !ticketId) return;

  const blockTrialTicket = [
    {
      ticket: ticketId,
      block: selectedSession.checkoutValue,
      checksum: selectedSession.checksum,
      blockTrialSelected: true,
      blockTrialType,
      blockTrialSessionCount,
      blockTrialPrice,
    },
  ];

  const createBasketInput: CreateBasketInput = {
    activity: activityId,
    tickets: blockTrialTicket,
    utmMeta:
      Object.keys(utmParams).length === 0
        ? {}
        : {
            utm_source: utmParams?.utm_source ?? null,
            utm_medium: utmParams?.utm_medium ?? null,
            utm_campaign: utmParams?.utm_campaign ?? null,
          },
  };

  return createBasketInput;
};

export const getBasketInput = (
  activityId: string,
  basketSchedules: BasketSchedule[],
  utmParams: UtmParamsData,
) => {
  const createBasketInput: CreateBasketInput = {
    activity: activityId,
    tickets: formatAllTicketsInBasket(basketSchedules),
    utmMeta:
      !utmParams || Object.keys(utmParams).length === 0
        ? {}
        : {
            utm_source: utmParams?.utm_source ?? null,
            utm_medium: utmParams?.utm_medium ?? null,
            utm_campaign: utmParams?.utm_campaign ?? null,
          },
  };

  return createBasketInput;
};

export const getTimeRanges = (
  blocks: Product[],
  sessions: ProductsByDate[],
  hideEndTimes: boolean,
) => {
  const timeRanges = new Set();

  blocks.forEach((block) => {
    const timeRange = hideEndTimes ? block.startTime : `${block.startTime} - ${block.endTime}`;
    timeRanges.add(timeRange);
  });

  sessions.forEach((session) => {
    session.products.forEach((product) => {
      const timeRange = hideEndTimes
        ? product.startTime
        : `${product.startTime} - ${product.endTime}`;
      timeRanges.add(timeRange);
    });
  });

  return Array.from(timeRanges);
};

export const generatePebbleAppLink = (routeName: string, basketId: string) => {
  if (routeName === 'login') {
    const params = {
      redirect: `/checkout/details`,
      basketId,
    };
    return `/login?${qs.stringify(params)}`;
  }

  const params = {
    redirect: `${window?.location.origin}/checkout/details`,
    basketId: basketId,
  };

  return `${process.env.NEXT_PUBLIC_PEBBLE_APP_ENDPOINT}${routeName}?${qs.stringify(params)}`;
};

export const handleRouting = (
  basketId: string,
  routerPush: (link: string) => void,
  routeName?: string,
) => {
  if (!routeName || routeName === 'guest') {
    const params = {
      basketId,
    };
    return routerPush(`/checkout/details?${qs.stringify(params)}`);
  } else {
    const link = generatePebbleAppLink(routeName, basketId);
    return routerPush(link);
  }
};
